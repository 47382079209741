import React from 'react';

const RockerSwitch = ({ isOn, toggle }) => {

  const [isMobileOrTablet, setIsMobileOrTablet] = React.useState(window.innerWidth <= 768);
  React.useEffect(() => {
    const handleResize = () => setIsMobileOrTablet(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // const styles = {
  //   switchContainer: {
  //     marginTop:'40px',
  //     marginRight:'20px',
      
  //     display: 'inline-block',
  //     position: 'relative',
  //     width: '30px',
  //     height: '60px',
  //     backgroundColor: '#bfbfbf',
  //   //   backgroundColor: '#333',
  //     borderRadius: '2px',
  //     borderLeft: '2px',
  //     paddingRight: ' 7px',
  //     borderColor: '#333',
  //     borderWidth: '6px',
  //     cursor: 'pointer',
  //     boxShadow: '0 0 2px rgba(0,0,0,0.5)',
  //   },
  //   switch: {
  //     position: 'absolute',        
  //     width: '95%',
  //     height: '95%',
  //     display: 'flex',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     backgroundColor: '#222',
  //     backgroundColor: '#c41e3a', // A deep red color
  //     transition: 'transform 0.2s',
  //     transform: isOn ? 'translateY(0px)' : 'translateY(0px)',
  //     boxShadow: isOn ? '0 0 30px #ff6b6b' : 'none', // Glow effect when ON
  //   },
  //   symbol: {
  //     color: '#fff',
  //     fontSize: '12px',
  //     fontWeight: 'bold',
  //   },
  //   onSymbol: {
  //       opacity: isOn ? 1.5 : 0.5,
  //     },
  //     offSymbol: {
  //       opacity: isOn ? 0.5 : 1.5,
  //     },

  // };

  const styles = {
    switchContainer: {
      marginTop: '40px',
      marginRight: '20px',
      display: 'inline-block',
      position: 'relative',
      width: '30px',
      height: '60px',
      backgroundColor: '#bfbfbf',
      borderRadius: '2px',
      paddingRight: '7px',
      borderColor: '#333',
      borderWidth: '6px',
      cursor: 'pointer',
      boxShadow: '0 0 2px rgba(0,0,0,0.5)',
  
      // For mobile and tablet, move the switch to the left center
      ...(isMobileOrTablet && {
        position: 'fixed',
        top: '55%',
        left: '15%',
        transform: 'translateY(-50%)',
      }),
    },
    switch: {
      position: 'absolute',
      width: '95%',
      height: '95%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#c41e3a', // Deep red color
      transition: 'transform 0.2s',
      transform: isOn ? 'translateY(0px)' : 'translateY(0px)',
      boxShadow: isOn ? '0 0 30px #ff6b6b' : 'none', // Glow effect when ON
    },
    symbol: {
      color: '#fff',
      fontSize: '12px',
      fontWeight: 'bold',
    },
    onSymbol: {
      opacity: isOn ? 1.5 : 0.5,
    },
    offSymbol: {
      opacity: isOn ? 0.5 : 1.5,
    },
  };

  return (
    <div style={styles.switchContainer} onClick={toggle}>
     <span style={{
        position: 'absolute',
        top: '-25px', // Positions label above the switch
        left: '50%',
        transform: 'translateX(-50%)', // Centers the label horizontally
        fontSize: '14px', // Optional: adjust font size as needed
        color: 'rgb(244, 146, 101)',
      }}>
        ON/OFF
      </span>
      <div style={styles.switch}>
     
        <span style={styles.symbol}>{isOn ? 'I' : 'O'}</span>
      </div>
    </div>
  );
};

export default RockerSwitch;
