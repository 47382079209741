import React, { useState, useEffect, useRef } from 'react';
import '../css/KnobControl.css';  // Make sure this file contains styles for the knob rotation.

const KnobControl = ({ value, onChange, min = 0, max = 1, step = 0.1 }) => {
  const [rotation, setRotation] = useState(0);
  const knobRef = useRef(null);
  const initialValueRef = useRef(value);
  const initialMouseYRef = useRef(null);

  useEffect(() => {
    const angle = (value - min) / (max - min) * 270 - 135; // Map value to rotation angle
    setRotation(angle);
  }, [value, min, max]);

  const handleMouseMove = (e) => {
    if (initialMouseYRef.current === null) {
      initialMouseYRef.current = e.clientY;
    }

    // Calculate the difference in vertical mouse movement
    const mouseDeltaY = initialMouseYRef.current - e.clientY;
    const range = max - min;
    
    // Adjust the sensitivity here, smaller values make it slower
    const sensitivity = 0.01;
    const deltaValue = mouseDeltaY * sensitivity * range;

    // Calculate the new value based on mouse movement
    let newValue = initialValueRef.current + deltaValue;

    // Clamp the value within the range
    newValue = Math.max(min, Math.min(max, newValue));

    onChange(newValue);  // Call parent onChange with the new value
  };

  const handleMouseDown = (e) => {
    initialValueRef.current = value;  // Store the initial value on mouse down
    initialMouseYRef.current = e.clientY;  // Store the starting mouse Y position

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', handleMouseMove);
      initialMouseYRef.current = null;  // Reset mouse position on release
    }, { once: true });
  };

  return (
    <div
      ref={knobRef}
      className="knob"
      style={{ transform: `rotate(${rotation}deg)` }}
      onMouseDown={handleMouseDown}
    />
  );
};

export default KnobControl;
