import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import App12 from '../src/components/App12';
import TapeRecorderUI from '../src/components/TapeRecorderUI';

function App() {
  return (
    
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Navigate to="/TapeRecorderUI" />} /> */}
        <Route path="/" element={<TapeRecorderUI />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
