import React, { useState, useEffect, useRef } from 'react';
// import coverImage from '../supportImages/radio/cover.jpg';  
// import radioBody from '../supportImages/radio/radiobody.jpg';  
// import frameBody from '../supportImages/radio/dark-brown-wood.jpg'; 
// import bushImage from '../supportImages/radio/bush1.png'; 

import BackgroundSlideshow from './BackgroundSlideshow';
import VolumeKnob from './VolumeKnob.js';
// import { SaturationKnob } from './Saturation.js';
import KnobControl from './knob.js';
import RockerSwitch from './RockerSwitch';
import '../css/Design.css';

const TapeRecorderUI = () => {
  const [playlist, setPlaylist] = useState([]);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [showControls, setShowControls] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(.5);
  const [bass, setBass] = useState(-4);
  const [mid, setMid] = useState(0.3);
  const [treble, setTreble] = useState(4);
  const [tone, setTone] = useState(0.7);
  const [saturation, setSaturation] = useState(0);
  // const [audioOutputs, setAudioOutputs] = useState([]);
  // const [selectedOutput, setSelectedOutput] = useState('');
  const [audioContextInitialized, setAudioContextInitialized] = useState(false);
  const [mode, setMode] = useState('fm');
  const [fmStations, setFmStations] = useState([]);
  // const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(0);
  const [currentPlaying, setCurrentPlaying] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  

  const audioContext = useRef(null);
  const sourceNode = useRef(null);
  const gainNode = useRef(null);
  const bassFilter = useRef(null);
  const midFilter = useRef(null);
  const trebleFilter = useRef(null);
  const toneFilter = useRef(null);
  const saturationNode = useRef(null);
  const audioElement = useRef(null);
  const currentPlayingRef = useRef(null);


  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust 768px as needed for your breakpoint
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);


  const initializeAudioContext = async () => {
    if (!audioContextInitialized) {
      try {
        // Create new audio context
        audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
        
        // Create audio nodes
        sourceNode.current = audioContext.current.createMediaElementSource(audioElement.current);
        gainNode.current = audioContext.current.createGain();
        bassFilter.current = audioContext.current.createBiquadFilter();
        midFilter.current = audioContext.current.createBiquadFilter();
        trebleFilter.current = audioContext.current.createBiquadFilter();
        toneFilter.current = audioContext.current.createBiquadFilter();
        saturationNode.current = audioContext.current.createWaveShaper();
  
        // Configure filters
        bassFilter.current.type = 'lowshelf';
        bassFilter.current.frequency.value = 200;
        bassFilter.current.gain.value = bass;

        
        midFilter.current.type = 'peaking';
        midFilter.current.frequency.value = 1000;
        midFilter.current.Q.value = 0.5;
        midFilter.current.gain.value = mid;
        
        trebleFilter.current.type = 'highshelf';
        trebleFilter.current.frequency.value = 3000;
        trebleFilter.current.gain.value = treble;
        
        toneFilter.current.type = 'lowpass';
        toneFilter.current.frequency.value = 1000;
  
        // Create media element source node
        // sourceNode.current = audioContext.current.createMediaElementSource(audioElement.current);
  
        // Connect the audio processing chain
        // connectAudioNodes();

        // Connect nodes
        sourceNode.current.connect(bassFilter.current);
        bassFilter.current.connect(midFilter.current);
        midFilter.current.connect(trebleFilter.current);
        trebleFilter.current.connect(toneFilter.current);
        toneFilter.current.connect(saturationNode.current);
        saturationNode.current.connect(gainNode.current);
        gainNode.current.connect(audioContext.current.destination);

        // Set initial volume
        gainNode.current.gain.value = volume;
  
        setAudioContextInitialized(true);
        console.log('Audio context initialized successfully');

        // Resume audio context (needed for some browsers)
        if (audioContext.current.state === 'suspended') {
          await audioContext.current.resume();
        }

      } catch (error) {
        console.error('Error initializing audio context:', error);
      }
    }
  };


  // Separate function to handle node connections
const connectAudioNodes = () => {
    try {
      // Disconnect any existing connections first
      sourceNode.current?.disconnect();
      gainNode.current?.disconnect();
      bassFilter.current?.disconnect();
      midFilter.current?.disconnect();
      trebleFilter.current?.disconnect();
      toneFilter.current?.disconnect();
      saturationNode.current?.disconnect();
  
      // Connect nodes in sequence
      sourceNode.current.connect(bassFilter.current);
      bassFilter.current.connect(midFilter.current);
      midFilter.current.connect(trebleFilter.current);
      trebleFilter.current.connect(toneFilter.current);
      toneFilter.current.connect(saturationNode.current);
      saturationNode.current.connect(gainNode.current);
      gainNode.current.connect(audioContext.current.destination);
      
      console.log('Audio nodes connected successfully');
    } catch (error) {
      console.error('Error connecting audio nodes:', error);
    }
  };



  useEffect(() => {
    // if (audioContextInitialized) {
      if (audioContextInitialized && audioContext.current) {
        try {
          // Update gain
      gainNode.current.gain.setValueAtTime(volume, audioContext.current.currentTime);
      bassFilter.current.gain.setValueAtTime(bass, audioContext.current.currentTime);
      midFilter.current.gain.setValueAtTime(mid, audioContext.current.currentTime);
      trebleFilter.current.gain.setValueAtTime(treble, audioContext.current.currentTime);
      
      const toneFreq = 200 + (tone * 3800);
      toneFilter.current.frequency.setValueAtTime(toneFreq, audioContext.current.currentTime);
      
      const saturateCurve = (amount) => {
        const k = Math.tanh(amount * 5);
        const n_samples = 44100;
        const curve = new Float32Array(n_samples);
        for (let i = 0; i < n_samples; i++) {
            const x = (i * 2) / n_samples - 1;
            curve[i] = (3 + k) * x / (1 + k * Math.abs(x));
        }
        return curve;
      };
    
      saturationNode.current.curve = saturateCurve(saturation);
    } catch (error) {
      console.error('Error updating audio parameters:', error);
    }
  }
  }, [volume, bass, mid, treble, tone, saturation, audioContextInitialized]);

  // Scroll to the currently playing item whenever it changes
  // useEffect(() => {
  //   if (currentPlayingRef.current) {
  //     currentPlayingRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //   }
  // }, [currentTrackIndex]);

  const handlePlayStation = (url, index) => {
    setCurrentPlaying(index);
    playStation(url);
    setCurrentPlayingIndex(index);
    // scrollToCurrentPlaying();
  };

  const handlePlayTrack = (track, index) => {
    setCurrentPlaying(index + fmStations.length);
    playTrack(track);
    setCurrentPlayingIndex(index + fmStations.length);
    // scrollToCurrentPlaying();
  };

//   const playStation = (url) => {
//     if (!audioElement.current.paused) {
//       audioElement.current.pause();
//     }
//     audioElement.current.src = url;
//     audioElement.current.load();
//     audioElement.current.play().catch((err) => {
//       console.error("Failed to play audio:", err);
//     });
//     setIsPlaying(false);
//   };


// Modified playStation function with proper error handling
const playStation = async (url) => {
    try {
      // Ensure audio context is initialized
      if (!audioContextInitialized) {
        await initializeAudioContext();
      }
  
      // Resume audio context if suspended
      if (audioContext.current?.state === 'suspended') {
        await audioContext.current.resume();
      }
  
      // Stop current playback
      // if (!audioElement.current.paused) {
      //   audioElement.current.pause();
      // }
  
      // Set new source and play
      // audioElement.current.src = url;
      // audioElement.current.load();

      audioElement.current.crossOrigin = 'anonymous';
      audioElement.current.src = url;
  
      // Wait for canplay event before playing
      await new Promise((resolve) => {
        audioElement.current.addEventListener('canplay', resolve, { once: true });
      });
  
      await audioElement.current.play();
      setIsPlaying(true);
      updateAudioSettings();
      
      console.log('Audio started playing successfully');
    } catch (err) {
      console.error("Failed to play audio:", err);
      setIsPlaying(true);
    }
  };


  // Update audio settings with error handling
const updateAudioSettings = () => {
    if (audioContextInitialized && audioContext.current) {
      try {
        const currentTime = audioContext.current.currentTime;
        
        // Update gain
        gainNode.current.gain.setValueAtTime(volume, currentTime);
        
        // Update filters
        bassFilter.current.gain.setValueAtTime(bass, currentTime);
        midFilter.current.gain.setValueAtTime(mid, currentTime);
        trebleFilter.current.gain.setValueAtTime(treble, currentTime);
        
        // Update tone
        const toneFreq = 200 + (tone * 3800);
        toneFilter.current.frequency.setValueAtTime(toneFreq, currentTime);
        
        // Update saturation
        const saturateCurve = (amount) => {
          const k = Math.tanh(amount * 5);
          const n_samples = 44100;
          const curve = new Float32Array(n_samples);
          for (let i = 0; i < n_samples; i++) {
            const x = (i * 2) / n_samples - 1;
            curve[i] = (3 + k) * x / (1 + k * Math.abs(x));
          }
          return curve;
        };
        
        saturationNode.current.curve = saturateCurve(saturation);
        console.log('Audio settings updated successfully');
      } catch (error) {
        console.error('Error updating audio settings:', error);
      }
    }
  };


  // Component mount effect
useEffect(() => {
    // Initialize audio element
    audioElement.current = new Audio();
    audioElement.current.crossOrigin = 'anonymous';  // Important for CORS
    
    // Clean up on unmount
    return () => {
      if (audioElement.current) {
        audioElement.current.pause();
        audioElement.current.src = ''; 
      }
      // if (audioContext.current && audioContext.current.state !== 'closed') {
      if (audioContext.current) {
      audioContext.current.close();
      }
    };
  }, []);
  
  // Audio parameters effect
  useEffect(() => {
    updateAudioSettings();
  }, [volume, bass, mid, treble, tone, saturation, audioContextInitialized]);



  useEffect(() => {
    if (mode === 'fm') {
      fetch('https://de1.api.radio-browser.info/json/stations/bycountry/India')
        .then((response) => response.json())
        .then((data) => {
          setFmStations(data);
          if (data.length > 1309) {
            playStation(data[1309].url);
            setCurrentPlayingIndex(1309);
            // scrollToCurrentPlaying();
          }
        })
        .catch((error) => console.error('Error fetching FM stations:', error));
    }
  }, [mode]);

  const toggleControls = () => {
    setShowControls(prevState => !prevState);
  };
  

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const mp3Files = files.filter(file => file.type === 'audio/mpeg');
    if (playlist.length + mp3Files.length > 10) {
      alert('You can only add up to 10 songs.');
      return;
    }
    const newTracks = mp3Files.map(file => ({
      name: file.name,
      file: file,
      url: URL.createObjectURL(file),
      type: 'music',
    }));
    setPlaylist((prevPlaylist) => [...prevPlaylist, ...newTracks]);
    if (newTracks.length > 0 && mode === 'music') {
      playTrack(newTracks[0]);
    }
  };

  // const playTrack = (track) => {
  //   if (!audioContextInitialized) {
  //     initializeAudioContext();
  //   }
  //   audioElement.current.src = track.url;
  //   audioElement.current.play();
  //   setCurrentTrack(track);
  //   setIsPlaying(true);
  // };

  const playTrack = async (track) => {
    try {
      if (!audioContextInitialized) {
        await initializeAudioContext();
      }

      if (audioContext.current?.state === 'suspended') {
        await audioContext.current.resume();
      }

      audioElement.current.src = track.url;
      await audioElement.current.play();
      setCurrentTrack(track);
      setIsPlaying(true);
    } catch (error) {
      console.error('Error playing track:', error);
      setIsPlaying(false);
    }
  };


  // Component cleanup
  useEffect(() => {
    audioElement.current.crossOrigin = 'anonymous';
    
    return () => {
      if (audioElement.current) {
        audioElement.current.pause();
        audioElement.current.src = '';
      }
      if (audioContext.current) {
        audioContext.current.close();
      }
    };
  }, []);

  const togglePlayPause = () => {
    if (audioElement.current) {
      if (isPlaying) {
        audioElement.current.pause();
      } else {
        audioElement.current.play().catch((err) => {
          console.error("Error during play():", err);
        });
      }
      setIsPlaying(!isPlaying);
    } else {
      alert("No track or FM station loaded to play or pause!");
    }
  };

  // const playNextTrack = () => {
  //   const nextIndex = (currentTrackIndex + 1) % (mode === 'fm' ? fmStations.length : playlist.length);
  //   if (mode === 'fm') {
  //     playStation(fmStations[nextIndex].url);
  //   } else {
  //     playTrack(playlist[nextIndex]);
  //   }
  //   setCurrentTrackIndex(nextIndex);
  //   scrollToCurrentPlaying();
  // };

  // const playPreviousTrack = () => {
  //   const prevIndex = (currentTrackIndex - 1 + (mode === 'fm' ? fmStations.length : playlist.length)) % (mode === 'fm' ? fmStations.length : playlist.length);
  //   if (mode === 'fm') {
  //     playStation(fmStations[prevIndex].url);
  //   } else {
  //     playTrack(playlist[prevIndex]);
  //   }
  //   setCurrentTrackIndex(prevIndex);
  // };

  const playNextTrack = () => {
    const nextIndex = (currentPlayingIndex + 1) % (mode === 'fm' ? fmStations.length : playlist.length);
    if (mode === 'fm') {
      playStation(fmStations[nextIndex].url);
    } else {
      playTrack(playlist[nextIndex]);
    }
    setCurrentPlayingIndex(nextIndex);
    // currentPlayingRef.current.scrollIntoView({
    //   behavior: 'smooth',
    //   // block: 'center',
    //   // inline: 'nearest',
    //   // text_align: 'center',
    //   top: '50%'
    // });
    // scrollToCurrentPlaying();
  };

  const playPreviousTrack = () => {
    const length = mode === 'fm' ? fmStations.length : playlist.length;
    const prevIndex = (currentPlayingIndex - 1 + length) % length;
    if (mode === 'fm') {
      playStation(fmStations[prevIndex].url);
    } else {
      playTrack(playlist[prevIndex]);
    }
    setCurrentPlayingIndex(prevIndex);
    // scrollToCurrentPlaying();
  };

  const deleteTrack = (trackToDelete) => {
    setPlaylist(playlist.filter(track => track !== trackToDelete));
    if (currentTrack === trackToDelete) {
      audioElement.current.pause();
      setCurrentTrack(null);
      setIsPlaying(false);
    }
  };

  return (
    <div className="outer">
     {!isMobile && <BackgroundSlideshow />}

      <div className="container">
        <h2 className="title">Philips VacuumTube Radio</h2>
        
        <div className="playlist">
          {mode === 'music' ? (
            <input className="fileInput" type="file" accept="audio/mp3*" multiple onChange={handleFileUpload} />
          ) : (
            <ul className="fmStationList">
              {fmStations.map((station, index) => (
                <li 
                  key={index} 
                  // className="trackItem" 
                  className={`trackItem ${currentPlayingIndex === index ? 'currentPlaying' : ''}`}
                  onClick={() => handlePlayStation(station.url, index)}
                  ref={currentPlaying === index ? currentPlayingRef : null}
                  >
                  <span className="fileInput">{index + 1}.{station.name}</span>
                </li>
              ))}
            </ul>
          )}

          {playlist.map((track, index) => (
            <div 
              key={index} 
              // className="trackItem">
              // <span onClick={() => playTrack(track)} className="trackName">
              //   {track.name}
              // </span>
              className={`trackItem ${currentPlayingIndex === index + fmStations.length ? 'currentPlaying' : ''}`}
              onClick={() => handlePlayTrack(track, index)}
              ref={currentPlaying === index + fmStations.length ? currentPlayingRef : null}
              >
              <span className="trackName">{track.name}</span>
              <button className="deleteButton" onClick={() => deleteTrack(track)}>
                Delete
              </button>
            </div>
          ))}
        </div>
      
        <div className="modeSelector">
          <label>MusicPlayer / FM Radio</label>
          <input
            type="range"
            min="0"
            max="1"
            step="1"
            value={mode === 'fm' ? 0 : 1}
            onChange={(e) => setMode(e.target.value === '0' ? 'fm' : 'music')}
          />
          <span><label>{mode === 'music' ? 'Music Player' : 'FM Radio'}</label></span>
        </div>

        <audio ref={audioElement} />

        <div className="controls">
          <RockerSwitch isOn={isPlaying} toggle={togglePlayPause} disabled={playlist.length === 0 && fmStations.length === 0} />
         
          <div className="controlGroup">
            {/* <label>Volume</label> */}
            <VolumeKnob audioElement={audioElement} volume={volume} onChange={setVolume} />
            <div><label>Volume : {(volume * 100).toFixed(0)}%</label></div>
          </div>

          <div className="controlToggleIcon" onClick={toggleControls}>
            🎛️ {/* Replace this emoji with an icon if you prefer */}
          </div>
          
          {/* Controls Group */}
          <div className={`controlsBox ${showControls ? 'show' : ''}`}>
              <div className="controlGroup">
                <label>Bass</label>
                <KnobControl value={bass} onChange={setBass} min={-10} max={10} step={0.01} sensitivity={2.3} />
              </div>
              
              <div className="controlGroup">
                <label>Mid</label>
                <KnobControl value={mid} onChange={setMid} min={-10} max={10} step={0.01}/>
              </div>
              
              <div className="controlGroup">
                <label>Treble</label>
                <KnobControl value={treble} onChange={setTreble} min={-10} max={10} step={0.01}/>
              </div>

              <div className="controlGroup">
                <label>Tone</label>
                <KnobControl value={tone} onChange={setTone} min={0} max={1} step={0.01} />
              </div>

              {/* <div className="controlGroup">
                <label>Saturation</label>
                <SaturationKnob saturation={saturation} onChange={setSaturation} mode={mode} step={0.01}/>
              </div> */}
            </div>
        </div>
        
        <div className="navigationButtons">
          <button onClick={playPreviousTrack}>Previous</button>
          <button onClick={playNextTrack}>Next  !!</button>
        </div>
      </div>
        {!isMobile && (
          <>
            <div className="leftBush"></div>
            <div className="rightBush"></div>
          </>
        )}
    </div>
  );
};

export default TapeRecorderUI;