import React, { useState, useEffect } from 'react';

// If using images within the 'src' folder
const images = [
  require('../supportImages/background/background01.webp'),
  require('../supportImages/background/background02.webp'),
  require('../supportImages/background/background03.webp'),
  require('../supportImages/background/background04.webp'),
  require('../supportImages/background/background05.webp'),
//   require('../supportImages/background/background06'),
  // require('../supportImages/background/background07.jpg'),
  // require('../supportImages/background/background08.jpg'),
  // require('../supportImages/background/background09.jpg'),
  // require('../supportImages/background/background10.jpg'),
  // require('../supportImages/background/background11.jpg'),
];

const BackgroundSlideshow = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
    }}>
      {images.map((image, index) => (
        <div
          key={index} // Use index as key if images don't have unique identifiers
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: index === currentImageIndex ? 1 : 0,
            transition: 'opacity 1s ease-in-out',
            filter: index === currentImageIndex
            ? 'blur(0px) brightness(1.2) contrast(1.2) saturate(1.2)'
            : 'blur(10px) brightness(0.8) contrast(0.8) saturate(0.8)',
          }}
        />
      ))}
    </div>
  );
};

export default BackgroundSlideshow;
