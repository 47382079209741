import React, { useEffect, useRef } from 'react';
import knobImage from '../supportImages/radio/pureknob.png'; 

const VolumeKnob = ({ audioElement, volume, onChange }) => {
  const knobRef = useRef(null);

//   React.useEffect(() => {
    
//     if (knobRef.current) {
//       const rotationDegrees = (volume - 1) * 135; // Map 0-2 to -135 to 135 degrees
//       knobRef.current.style.transform = `rotate(${rotationDegrees}deg)`;
//     }
//   }, [volume]);

//   const handleChange = (e) => {
//     const newVolume = parseFloat(e.target.value);
//     onChange(newVolume);
//   };

  useEffect(() => {

  const clampedVolume = Math.max(0, Math.min(1, volume / 2));
    console.log(`Setting audio volume to: ${clampedVolume}`); 
  // Safely access the audio element
  if (audioElement && audioElement.current) {
    // audioElement.current.volume = volume; // Ensure this is within the 0-1 range
    // const clampedVolume = Math.max(0, Math.min(1, volume));
    audioElement.current.volume = clampedVolume; // Set volume within valid range
  }

  if (knobRef.current) {
    // Rotate the knob based on the volume value
    const rotationDegrees = (volume - 1) * 135; // Map 0-2 to -135 to 135 degrees
    knobRef.current.style.transform = `rotate(${rotationDegrees}deg)`;
  }
}, [volume, audioElement]);

const handleChange = (e) => {
  const newVolume = parseFloat(e.target.value);
  // onChange(newVolume);
  // Call the parent onChange function with clamped volume
  console.log(`Volume slider changed to: ${newVolume}`);
  onChange(Math.max(0, Math.min(2, newVolume))); // Ensure volume is within 0-2 range for UI purposes
};

  const styles = {
    // knobContainer: {
    //   position: 'relative',
    //   width: '90px',
    //   height: '90px', 
    //   marginTop: '15px',  
    //   marginLeft: '10px',    
    // },
    // knob: {
    //   WebkitAppearance: 'none',
    //   appearance: 'none',
    //   width: '100%',
    //   height: '100%',
    //   background: 'transparent',
    //   outline: 'none',
    //   opacity: 0,
    //   cursor: 'pointer',      
    // },
    // knobImage: {
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   width: '100%',
    //   height: '100%',
    //   backgroundImage: `url(${knobImage})`,
    //   backgroundSize: 'cover',
    //   borderRadius: '50%',
    //   pointerEvents: 'none',
    //   transition: 'transform 0.1s ease-out',
    // },
    knobContainer: {
    position: 'relative',
    width: '90px',
    height: '90px',
    marginRight: '0px', 
    marginTop: '15px',  
    marginBottom: '10px',

    // Responsive styling
    '@media (max-width: 768px)': { // Tablet
      width: '70px',
      height: '70px',
      marginTop: '10px',
      margin: 'auto', 
      display: 'flex',
      alignItems: 'center',
    },
    '@media (max-width: 480px)': { // Mobile
      width: '50px',
      height: '50px',
      marginTop: '8px',
      margin: 'auto', 
      display: 'flex',
      alignItems: 'center',
    },
  },
  knob: {
    WebkitAppearance: 'none',
    appearance: 'none',
    width: '100%',
    height: '100%',
    background: 'transparent',
    outline: 'none',
    opacity: 0,
    cursor: 'pointer',
  },
  knobImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${knobImage})`,
    backgroundSize: 'cover',
    borderRadius: '50%',
    pointerEvents: 'none',
    transition: 'transform 0.1s ease-out',

    // Responsive styling for smaller screens
    '@media (max-width: 768px)': { // Tablet
      transition: 'transform 0.08s ease-out',
    },
    '@media (max-width: 480px)': { // Mobile
      transition: 'transform 0.06s ease-out',
    },
  },

  };

  return (
    <div style={styles.knobContainer}>
      <input
      // ref={knobRef}
        type="range"
        min="0"
        max="1.7"
        step="0.01"
        value={volume}
        onChange={handleChange}
        // style={{styles.knob} { transform: `rotate(${(volume - 1) * 135}deg)` }} // Visual rotation
        style={styles.knob}
      />
      <div ref={knobRef} style={styles.knobImage}></div>
    </div>
  );
};

export default VolumeKnob;